body {
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.vertical {
  height: 100vh;
  right: 0;
  position: absolute;
  width: 25%;
}
.header {
  overflow: hidden;
  color: black;
  background-color: white;
  width: 100%;
  margin: 0;
  border-bottom: solid gray 10px;
  padding-bottom: 5px;
}

.header a {
  float: left;
  color: black;
  text-align: center;
  padding-top: 12px;
  padding-left: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  font-family: Bahnschrift;
}


.header a.title {
  font-size: 25px;
  font-weight: bold;
  color: gray;

}

.header a.active {
  background-color: gray;
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}

/* The navigation menu links */
.sidenav a {
  padding: 6px 0px 6px 0px;
  margin: 10px;
  text-decoration: none;
  font-size: 20px;
  font-family: Bahnschrift;
  display: block;
}
/* The sidebar menu */
.sidenav {
  height: 100vh; /* Full-height: remove this if you want "auto" height */
  width: 100%;
  right: 0; /* Set the width of the sidebar */
  background-image: url("../public/deechtibree.jpg");
  background-size: 100% 100%;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}
/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: darkgray;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.man {
  overflow: scroll;
  width: 72%;
  left: 0;
  float: left;
  white-space: pre-wrap;
  margin-left: 1%;
  height: 90%;
  padding-bottom: 1%;
}
img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
img:hover {
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  }

#inflasione {
  float: right;
  margin: 10px;
}

#gazer {
  float: right;
  margin: 10px;
  height: 200px;
  width: 300px;
}

#gazero {
  float: left;
  margin: 10px;
  height: 200px;
  width: 300px;
}

#ohneJob {
  float: right;
  margin: 10px;
  height: 200px;
  width: 400px;
}


#text {
  display: block;
  font-family: Bahnschrift;
  font-size: large;
  white-space: pre-wrap;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-right: 10px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

.hinter {
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  font-family: Bahnschrift;
  background-color: lightgray;
  color: black;
  opacity: 10;
}

#nav-home-img{
  width: 25px;
  height: 25px;
  margin-right: 50px;
}

#swissUSA {
  height: 100%;
  width: 100%;
}
#outgabe {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

#diagramm {
  background-color: lightgray;
}